<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <router-link class="btn btn-primary" v-if="this.$hasPermission('onboarding-new-employees')"
              :to="{ name: 'create-admin' }">
              Add New User
            </router-link>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input @keyup="checkString($event)" v-model="searchQuery" class="d-inline-block mr-1"
                placeholder="Search..." :class="{ red_border: notEmpty }" />
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary" @click="masterSearch()">
                Filter
              </button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row>
      <b-col>
        <b-button style="float: right;" class="btn btn-success mr-2"  size="sm" @click="exportUsers()">
              Export data
            </b-button>
     </b-col>
      </b-row>


      <b-table ref="refUserListTable" class="position-relative mt-1" responsive primary-key="id" :items="listClients"
        empty-text="No users found" show-empty :fields="fields" :per-page="0" id="my-table" :current-page="currentPage">
        <template #cell(role)="{ item }">
          <span>
            {{ item.role.role }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" transition="scale-transition"
            :offset-y="true" :disabled="$store.getters['Users/userData'].user.role.id === 1 &&
              item.role.id === 3
              ">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <template style="padding: 0" v-slot:activator="{ on, attrs }">
              <b-btn color="primary" v-bind="attrs" v-on="on" icon ripple>
              </b-btn>
            </template>
            <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded v-if="$store.getters['Users/userData'].user.role.id === 1 &&
              item.role.id === 2
              ">
              <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                  @click="changeForSuper(item.id)">
                  <feather-icon icon="FileTextIcon" />
                  Change to Super Admin
                </b-list-group-item>
              </b-list-group-item>
            </b-list-group>
            <b-list-group style="padding: 2px; margin-bottom: 2px" v-if="$store.getters['Users/userData'].user.role.id === 1 &&
              item.role.id === 1
              " dense rounded>
              <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                  @click="changeForAdmin(item.id)">
                  <feather-icon icon="FileTextIcon" />
                  Change to Admin
                </b-list-group-item>
              </b-list-group-item>
            </b-list-group>

            <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded v-if="hasPermissionRemoveUser">
              <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false"
                v-if="$hasPermission('delete-records')">
                <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px"
                  @click="deleteItem(item.id)">
                  <feather-icon icon="TrashIcon" />
                  Delete
                </b-list-group-item>
              </b-list-group-item>
            </b-list-group>

            <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded>
              <router-link class="urlPagina" :to="{ name: 'edit-admin', params: { item: item } }">
                <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                  <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                    <feather-icon icon="FileTextIcon" />
                    Edit
                  </b-list-group-item>
                </b-list-group-item>
              </router-link>
            </b-list-group>

            <b-list-group style="padding: 2px; margin-bottom: 2px" dense rounded v-if="hasPermissionResetPasswords">
              <router-link class="urlPagina" :to="{ name: 'change-password-admin', params: { item: item } }">
                <b-list-group-item style="padding: 0" class="urlPagina" :ripple="false">
                  <b-list-group-item class="font-weight-bold" style="border: none; padding: 5px">
                    <feather-icon icon="FileTextIcon" />
                    Change password
                  </b-list-group-item>
                </b-list-group-item>
              </router-link>
            </b-list-group>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of
              {{ totalItems }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <div class="pagination_block">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                    <button type="button" class="page-link" @click="getClientes(pagination.first_page_url)">
                      &laquo;
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
                    <button type="button" class="page-link" @click="getClientes(pagination.prev_page_url)">
                      Previous
                    </button>
                  </li>
                  <li class="page-item" v-if="currentPage - 2 > 0">
                    <button type="button" class="page-link" @click="
                      getClientes(
                        pagination.path + '?page=' + (currentPage - 2)
                      )
                      ">
                      {{ currentPage - 2 }}
                    </button>
                  </li>
                  <li class="page-item" v-if="pagination.prev_page_url">
                    <button type="button" class="page-link" @click="getClientes(pagination.prev_page_url)">
                      {{ currentPage - 1 }}
                    </button>
                  </li>
                  <li class="page-item">
                    <button type="button" class="page-link" :class="{
                      current: pagination.current_page == currentPage,
                    }">
                      {{ currentPage }}
                    </button>
                  </li>
                  <li class="page-item" v-if="pagination.next_page_url">
                    <button type="button" class="page-link" @click="getClientes(pagination.next_page_url)">
                      {{ currentPage + 1 }}
                    </button>
                  </li>
                  <li class="page-item" v-if="currentPage + 2 <= lastPage">
                    <button type="button" class="page-link" @click="
                      getClientes(
                        pagination.path + '?page=' + (currentPage + 2)
                      )
                      ">
                      {{ currentPage + 2 }}
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                    <button type="button" class="page-link" @click="getClientes(pagination.next_page_url)">
                      Next
                    </button>
                  </li>
                  <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
                    <button type="button" class="page-link" @click="getClientes(pagination.last_page_url)">
                      &raquo;
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {  getMessageError } from "@core/utils/utils";

// import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from "@core/components/infoClients/UserListAddNew";

export default {
  name: "ListAssignRoles",
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      listClients: [],
      perPage: 0,
      currentPage: 1,
      searchQuery: "",
      pageOptions: [3, 5, 10],
      hasPermissionResetPasswords: false,
      hasPermissionRemoveUser: false,
      fields: ["name", "email", "role", "actions"],
      totalItems: 0,
      lastPage: 0,
      pagination: {},
      notEmpty: false,
    };
  },
  methods: {
    checkString(e) {
      if (this.searchQuery == "") {
        this.getClientes();
      }
      if (e.keyCode == 13) {
        this.masterSearch();
      }
      this.notEmpty = false;
    },
    masterSearch(url = "") {
      let userId = this.$store.getters['Users/userData'].user.id;
      if (this.searchQuery == "") {
        this.notEmpty = true;
      } else {
        this.notEmpty = false;
        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        if (url == "") {
          url = `admin/panel/users/list/search/` + this.searchQuery;
        }

        this.$http
          .get(url)
          .then((response) => {
            this.listClients = response.data.data.data;
            //remove current user from list
            this.listClients= this.listClients.filter(x=>x.id!=userId);
            //remove user where role is corporate account
            this.listClients= this.listClients.filter(x=>x.role.id!=3);          
            this.totalItems = response.data.data.total;
            this.currentPage = response.data.data.current_page;
            this.lastPage = response.data.data.last_page;
            this.pagination = response.data.data;
            this.perPage = response.data.data.per_page;
            this.$store.commit("Users/usersData", this.listClients);
            this.$swal.close();
          })
          .catch((res) => console.log(res.data));
      }
    },
    getClientes(url = "") {
      if (url == "") {
        url = "admin/panel/users/list";
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(url)
        .then((response) => {
          this.listClients = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.currentPage = response.data.data.current_page;
          this.lastPage = response.data.data.last_page;
          this.pagination = response.data.data;
          this.perPage = response.data.data.per_page;

          this.$swal.close();
        })
        .catch((res) => console.log(res.data));
    },

    changeForSuper(id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      let roleSuper = 1;
      this.$http
        .post(`/admin/panel/users/${id}/changeRol`, { role: roleSuper })
        .then((response) => {
          this.$swal({
            title: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getClientes();
            }
          });
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    changeForAdmin(id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      let roleAdmin = 2;
      this.$http
        .post(`/admin/panel/users/${id}/changeRol`, { role: roleAdmin })
        .then((response) => {
          this.$swal({
            title: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.getClientes();
            }
          });
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Do you want delete this record?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
      }).then((result) => {
        if (result.value === true) {
          // <-- if confirmed
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$http
            .post(`auth/admin/delete/${id}`)
            .then((res) => {
              this.getClientes();
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                title: error.response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    loadPermissions() {
      this.hasPermissionResetPasswords = this.$hasPermission("reset-passwords");
      this.hasPermissionRemoveUser = this.$hasPermission(
        "approval-removal-of-users"
      );
    },

    getValuesParameters() {
      let data = {};

      //Status variable filter
      let status = {};
      if (this.selectedStatus !== null) {
        status = this.selectedStatus.map((item) => {
          let value = item.value;
          return value;
        });
      }

      return status;
    },
    async exportUsers() {
      let res = "";

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`/export_users`, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/zip",
          }
        })
        .then((response) => {

          var blob = new window.Blob([response.data], {
            type: "application/zip",
          });
          if (blob.size > 0) {
            var url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            var win = window.open();
            win.document.write(
              '<iframe src="' +
              url +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            );
          }

          this.$swal.close();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },
  mounted() {
    this.getClientes();
    this.loadPermissions();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.urlPagina {
  text-decoration: none;
  color: #7367f0;
}

.urlPagina:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.list-group-item:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff !important;
  cursor: pointer;
}

.urlPagina::before {
  background-color: currentColor !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
  box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.current {
  background: #7367f0;
  color: white;
}

.red_border {
  border: 1px solid red;
  border-radius: 6px;
}

.table-responsive {
  overflow-x: clip !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
